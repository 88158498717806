<template>
  <div class="p-4 lg:w-1/2">
    <div
      class="h-full flex sm:flex-row flex-col sm:justify-start justify-center"
    >
      <router-link class="flex-none pic mb-3 sm:mb-0" :to="{ name: url }">
        <img
          alt="team"
          class="
            flex-shrink-0
            rounded-lg
            object-cover object-center
            sm:mb-0
            mb-4
          "
          :src="pic"
        />
      </router-link>
      <div class="flex-grow sm:pl-6">
        <h2 class="h5 text-royalblue-400 mb-2 leading-relaxed">
          <router-link class="no-formatting" :to="{ name: url }">
            {{ name }}
          </router-link>
        </h2>
        <p class="mb-3">
          {{ areas }}
        </p>
        <p class="text-royalblue-400 text-sm font-semibold mb-0">{{ tel }}</p>
        <p class="text-royalblue-400 text-sm">
          <a :href="`mailto:` + email">{{ email }}</a>
        </p>
        <p></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pic {
  width: 120px;
  height: 160px;
}
</style>

<script>
export default {
  props: {
    pic: {
      Type: String,
      Required: true,
    },
    url: {
      Type: String,
      Required: true,
    },
    name: {
      Type: String,
      Required: true,
    },
    areas: {
      Type: String,
      Required: true,
    },
    tel: {
      Type: String,
      Required: false,
    },
    email: {
      Type: String,
      Required: false,
    },
  },
};
</script>
