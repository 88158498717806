<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner pt-6 md:pt-12">
      <div class="mb-14 sm:mb-20">
        <h1 class="text-royalblue-900 mb-10">Meet The Team</h1>
        <div class="flex flex-wrap -m-4">
          <team-member-thumb
            v-for="(team_member, index) in team_members"
            :key="index"
            :pic="team_member.pic"
            :url="team_member.url"
            :name="team_member.name"
            :areas="team_member.areas"
            :tel="team_member.tel"
            :email="team_member.email"
          />
        </div>
      </div>

      <div class="flex flex-wrap -m-4">
        <feature-3-cols
          :icon="['far', 'user']"
          title="Meet the team"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Meet The Team"
        />
        <feature-3-cols
          :icon="['far', 'heart']"
          title="Our commitment to you"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Our Commitment To You"
        />
        <feature-3-cols
          :icon="['far', 'map']"
          title="Areas covered"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Areas Covered"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TeamMemberThumb from "../components/TeamMemberThumb.vue";
import Feature3Cols from "../components/Feature3Cols.vue";
import GemmaThumbPic from "../assets/images/thumb/gemma.jpg";
import DianeThumbPic from "../assets/images/thumb/diane.jpg";
import HeatherThumbPic from "../assets/images/thumb/heather.jpg";
import AlahnaThumbPic from "../assets/images/thumb/alahna.jpg";
import AdrianThumbPic from "../assets/images/thumb/adrian.jpg";
import ClaireThumbPic from "../assets/images/thumb/claire.jpg";
import DorotaThumbPic from "../assets/images/thumb/dorota.jpg";

export default {
  name: "Meet The Team",
  components: {
    "team-member-thumb": TeamMemberThumb,
    "feature-3-cols": Feature3Cols,
  },
  data() {
    return {
      team_members: [
        {
          pic: GemmaThumbPic,
          url: "Gemma",
          name: "Gemma Brown",
          areas:
            "Cowplain, Clanfield, Waterlooville, Portsmouth, Denmead, Soberton and Wickham",
          tel: "07834468951",
          email: "gemmabrown@balanceneurophysio.com",
        },
        {
          pic: DianeThumbPic,
          url: "Diane",
          name: "Diane Ormsby",
          areas:
            "Fareham, Gosport, Lee-on-the Solent, Locksheath, Warsash and Sarisbury Green",
          tel: "07757115336",
          email: "dianeormsby@balanceneurophysio.com",
        },
        {
          pic: HeatherThumbPic,
          url: "Heather",
          name: "Heather Ross",
          areas:
            "Burseldon, Hamble, Chandlers Ford, Hedge End, Bishops Waltham, Eastern Southampton",
          tel: "07743391322",
          email: "heatherross@balanceneurophysio.com",
        },
        {
          pic: AlahnaThumbPic,
          url: "Alahna",
          name: "Alahna Cullen",
          areas: "Ringwood, Verwood, Ferndown and nearby areas",
          tel: "07825669558",
          email: "alahna.cullen@gmail.com",
        },
        {
          pic: AdrianThumbPic,
          url: "Adrian",
          name: "Adrian Yeoman",
          areas: "Vestibular Physiotherapy. South Hampshire and West Sussex",
          tel: "07504448430",
          email: "adrianyeoman@balanceneurophysio.com",
        },
        {
          pic: ClaireThumbPic,
          url: "Claire",
          name: "Claire Cook",
          areas: "Academic and MS Specialist Advisor",
          tel: "Claire is currently unavailable due to academic commitments.",
        },
        {
          pic: DorotaThumbPic,
          url: "Dorota",
          name: "Dorota Laska-Gaffney",
          areas: "Portsmouth City",
          tel: "07593239863",
          email: "dorotalaska@balanceneurophysio.com",
        },
      ],
    };
  },
};
</script>
